import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"


import img1 from "../images/plomberie1.jpg"
import img2 from "../images/plomberie2.jpg"
import img3 from "../images/plomberie3.jpg"

const PlomberiePage  = () => (
    <Layout>
        <SEO 
            page="plomberie"
            title="Plombier à Mions, dépannage de plomberie"
            description="Air Water System est votre plombier à Mions, installation dépannage, urgence contactez nous au 06 98 99 96 96 intervention rapide"
            keywords={["Dépannage","Entreprise","Société","Artisan","Réparation","Entretien","Maintenance","Installation","Cuisine","Salle de Bain","Rénovation","Panne","Aménagement", "plomberie","canalisations","eau chaude","plombier","robinet","sanitaires","chauffe eau","canalisation","tuyauterie","évacuation","robinetterie","tuyaux","chauffe","fuites","sanitaire","fuite","plombiers","lavabo","dépannage",
            "chaudière","étanchéité","baignoire","évier","climatisation","raccords","travaux de plomberie","salle de bains","tuyau","usées","artisan","cuivre","installations","chaudières","chaude","robinets","toilettes","chasse d eau","salle de bain","installations sanitaires","raccordement","chauffagiste","eaux usées","salles de bains","assainissement","mitigeur","fuite d eau","plomberie chauffage",
            "entreprise de plomberie","ballon d eau","débouchage","réparer","installateur","siphon","plombier chauffagiste","radiateur","pvc","réparation","soudure","installation sanitaire","dépannage plomberie","chauffagistes","raccordements","dépannages","ballon d eau chaude","plomberie sanitaire","équipements sanitaires","installation de chauffage" 
            ]} 
        />
        <section className="section">
            <div className="container">
                <h1 className="title">Plombiers à Mions</h1>
                <h2 className="subtitle">Dépannage et installation de plomberie à Mions</h2>
                <p className="content">
                    Confiez vos travaux de plomberie à Air Water System à Mions !
                    <br></br>
                    Nos plombiers interviennent dans l’installation, la rénovation et l’entretien de vos sanitaires. Nous prenons en charge également le dépannage de votre sanitaire.
                    <br></br>
                    Nos plombiers climaticiens vous accompagnent dans tous vos projets d’installation de votre salle de bains et de votre cuisine.
                    <br></br>
                    Nous vous garantissons, un service de qualité qui s’adapte avec votre besoin et à la hauteur de vos attentes.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Des professionnels de la plomberie à votre service</h3>
                <p className="content">
                    Vous êtes victime d'un dégât des eaux ? Votre lavabo est bouché ou vos w.c connaissent un dysfonctionnement ? Votre système de chauffage vient de tomber en panne ? Faites appel aux experts de Air Water System pour vous dépanner. 
                    Notre entreprise est située à Mions dans le Rhône et nos artisans plombiers interviennent chez les particuliers et les professionnels pour entretenir et réparer votre installation de plomberie.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Quels sont nos zones d’intervention à Mions et dans les environs ?</h3>
                <p className="content">
                    Un ballon d'eau chaude défectueux ou des problèmes avec votre mitigeur ? Une chasse d'eau en panne ou une installation de robinetterie obsolète ? Toutes ces situations gênantes réclament l'intervention d'un plombier chauffagiste dans les plus brefs délais. 
                    Nos plombiers se déplacent directement chez vous , à Mions et dans les communes proches comme Lyon, Grenoble ou Chassieu. 
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Quels sont les conseils de vos spécialistes de la plomberie ?</h3>
                <p className="content">
                    Nos artisans vous prodiguent de précieux conseils pour votre installation de chauffage et vos équipements sanitaires. Les spécialistes de la société Air Water System possèdent tout le matériel nécessaire pour effectuer l'entretien et les réparations qui s'imposent. 
                    Si vous remarquez que l'eau ne s'évacue plus assez vite dans le siphon de la baignoire ou du lavabo, cela signifie que des saletés se sont accumulées dans les canalisations et commencent à boucher la tuyauterie. C'est à ce moment précis que nos professionnels du débouchage interviennent : remplacer un robinet, déboucher les tuyaux, remettre tout le système d'assainissement en service, quel que soit le problème de plomberie que vous rencontrez, notre entreprise change et remet en état toutes vos installations de plomberie.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Des experts du dépannage</h3>
                <p className="content">
                    Si vous remarquez des traces d'humidité sur vos murs, il est fort possible qu'une canalisation a cédé à l'intérieur, voire à l'extérieur de votre habitat. Vous ne devez pas laisser l'humidité s'imprégner dans les murs et dans le sol au risque de voir de la moisissure s'installer durablement chez vous. 
                    Si les eaux usées de votre habitat ne s'évacuent plus convenablement, c'est que les canalisations ne jouent plus leur rôle d'étanchéité. Elles peuvent être percées ou obstruées par des impuretés. Nos professionnels vont trouver les causes de ce problème et le résoudre rapidement.   
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Une entreprise de plomberie pour vos aménagements de cuisine et de salle de bains à Mions</h3>
                <p className="content">
                    Vous avez un projet de rénovation de salle de bains ? Votre chaudière est trop énergivore, ne fournit plus d'eau chaude et vous souhaitez la remplacer ? Les installateurs de l'entrepris Air Water System s'occupent de tous les raccordements de tuyauterie, que vos canalisations soient en cuivre ou en PVC, et vous installent votre nouvelle baignoire ou cabine de douche. 
                    Nos artisans plombiers sont également des chauffagistes professionnels et ils se chargent également de mettre en place votre nouvelle chaudière. De plus, nos plombiers gèrent toute la maintenance de vos installations. Confiez le remplacement, la réparation ou l'entretien de vos radiateurs et climatisations à nos artisans plombiers qui vont effectuer une prestation efficace et durable. 
                    Nous nous chargeons également de tous les travaux de plomberie dans votre cuisine. Notre entreprise vous propose des services optimisés qui s'adaptent à tous vos besoins.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div className="content">
                    <div class="columns">
                        <div class="column">
                            <figure class="image">
                                <img alt="Plombier Mions" src={img1}></img>
                            </figure>
                        </div>
                        <div class="column">
                            <figure class="image">
                                <img alt="Dépannage plomberie Mions" src={img2}></img>
                            </figure>
                        </div>
                        <div class="column">
                            <figure class="image">
                                <img alt="Installation plombier Moins" src={img3}></img>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <h3 className="title is-4">Quels sont les services de proximité de votre plombier à Mions ?</h3>
                <p className="content">
                    Vous êtes à la recherche d’un artisan plombier pour installer 
                    <br></br>
                    ou changer les sanitaires de votre maison ?
                    <br></br>
                    Votre système de chauffage est en panne ou vous êtes victime d’une fuite d’eau ?
                    <br></br>
                    Les plombiers de Air Water System à Mions interviennent pour tous vos travaux de plomberie en neuf ou en rénovation.
                    <br></br>
                    Combinant savoir-faire et professionnalisme, nos plombiers chauffagistes vous garantissent un service rapide pour tous vos dépannages urgents.
                    <br></br>
                    Vous avez besoin de rafraîchir votre salle de bain ?
                    <br></br>
                    Nous proposons aussi l'installation et l'aménagement de votre robinetterie, vasque, douche et baignoire.
                </p>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <p className="content">
                    Pour plus de détails concernant l'installation ou la réparation de votre chauffage, votre plomberie ou votre climatisation,  n’hésitez pas à contacter notre entreprise Air Water System à Mions.
                </p>
            </div>
        </section>
    </Layout>
)

export default PlomberiePage;
